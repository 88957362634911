import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { axiosPost } from "../../helpers/axiosHelper";

import "../../css/registration.css"; // Import the CSS file

const registerUrl = process.env.REACT_APP_REGISTER_API_URL;

export default function Register() {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    businessName: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showTooltip, setShowTooltip] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [passwordChecks, setPasswordChecks] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  });

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleCancel = () => {
    setFormValues({
      firstName: "",
      lastName: "",
      emailAddress: "",
      businessName: "",
      password: "",
    });
    setErrors({});
    setShowTooltip({});
    navigate("/login");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const { [name]: removedError, ...rest } = prevErrors;
        return rest;
      });
    }
    setShowTooltip((prevTooltip) => ({ ...prevTooltip, [name]: false }));

    if (name === "password") {
      setPasswordChecks({
        length: value.length >= 15,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    const newErrors = {};

    if (!formValues.firstName.trim())
      newErrors.firstName = "First Name is required";
    if (!formValues.lastName.trim())
      newErrors.lastName = "Last Name is required";
    if (
      !formValues.emailAddress.trim() ||
      !validateEmail(formValues.emailAddress)
    )
      newErrors.emailAddress = "Valid email is required";
    if (!formValues.businessName.trim())
      newErrors.businessName = "Business Name is required";
    if (
      !passwordChecks.length ||
      !passwordChecks.uppercase ||
      !passwordChecks.lowercase ||
      !passwordChecks.specialChar
    )
      newErrors.password = "Password does not meet the required criteria";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    axiosPost(registerUrl, formValues)
      .then((response) => {
        setMessage("Registration Successful");
        setErrorMessage(null);
        setFormValues({
          firstName: "",
          lastName: "",
          emailAddress: "",
          businessName: "",
          password: "",
        });
        setPasswordChecks({
          length: false,
          uppercase: false,
          lowercase: false,
          specialChar: false,
        });
        navigate("/login");
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Sorry... the backend server is down");
        }
        setMessage(null);
      });
  };

  return (
    <div className="registration-form-container">
      <form onSubmit={submitHandler}>
        <h5>Register</h5>

        <div className="form-group">
          <label>
            <span>First Name:</span>
          </label>
          <input
            type="text"
            name="firstName"
            value={formValues.firstName}
            onChange={handleChange}
          />
          {errors.firstName && (
            <Tooltip
              title={errors.firstName}
              open={showTooltip.firstName}
              arrow
              placement="right"
            >
              <ErrorOutlineIcon
                className="error-icon"
                onMouseEnter={() =>
                  setShowTooltip((prev) => ({ ...prev, firstName: true }))
                }
                onMouseLeave={() =>
                  setShowTooltip((prev) => ({ ...prev, firstName: false }))
                }
              />
            </Tooltip>
          )}
        </div>

        <div className="form-group">
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={formValues.lastName}
            onChange={handleChange}
          />
          {errors.lastName && (
            <Tooltip
              title={errors.lastName}
              open={showTooltip.lastName}
              arrow
              placement="right"
            >
              <ErrorOutlineIcon
                className="error-icon"
                onMouseEnter={() =>
                  setShowTooltip((prev) => ({ ...prev, lastName: true }))
                }
                onMouseLeave={() =>
                  setShowTooltip((prev) => ({ ...prev, lastName: false }))
                }
              />
            </Tooltip>
          )}
        </div>

        <div className="form-group">
          <label>Email Address:</label>
          <input
            type="email"
            name="emailAddress"
            value={formValues.emailAddress}
            onChange={handleChange}
          />
          {errors.emailAddress && (
            <Tooltip
              title={errors.emailAddress}
              open={showTooltip.emailAddress}
              arrow
              placement="right"
            >
              <ErrorOutlineIcon
                className="error-icon"
                onMouseEnter={() =>
                  setShowTooltip((prev) => ({ ...prev, emailAddress: true }))
                }
                onMouseLeave={() =>
                  setShowTooltip((prev) => ({ ...prev, emailAddress: false }))
                }
              />
            </Tooltip>
          )}
        </div>

        <div className="form-group">
          <label>Business Name:</label>
          <input
            type="text"
            name="businessName"
            value={formValues.businessName}
            onChange={handleChange}
          />
          {errors.businessName && (
            <Tooltip
              title={errors.businessName}
              open={showTooltip.businessName}
              arrow
              placement="right"
            >
              <ErrorOutlineIcon
                className="error-icon"
                onMouseEnter={() =>
                  setShowTooltip((prev) => ({ ...prev, businessName: true }))
                }
                onMouseLeave={() =>
                  setShowTooltip((prev) => ({ ...prev, businessName: false }))
                }
              />
            </Tooltip>
          )}
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={formValues.password}
            onChange={handleChange}
          />
        </div>
        {/* Password Strength Checker */}
        <div className="password-checks">
          <p>Password must contain:</p>
          <p>
            {passwordChecks.length ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}{" "}
            At least 15 characters
          </p>
          <p>
            {passwordChecks.uppercase ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}{" "}
            At least 1 uppercase letter
          </p>
          <p>
            {passwordChecks.lowercase ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}{" "}
            At least 1 lowercase letter
          </p>
          <p>
            {passwordChecks.specialChar ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <CancelIcon style={{ color: "red" }} />
            )}{" "}
            At least 1 special character
          </p>
        </div>

        <input type="submit" value="Register" />
        <button type="button" onClick={handleCancel}>
          Cancel
        </button>

        {message && <p className="message success">{message}</p>}
        {errorMessage && <p className="message error">{errorMessage}</p>}
      </form>
    </div>
  );
}
