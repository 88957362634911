import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import { NextUIProvider } from "@nextui-org/react";

import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import Navigation from "../components/publicweb/Navigation";
import Footer from "../components/publicweb/Footer";
import MainHome from "../pages/main/Home";
import Login from "../pages/main/Login";
import Register from "../pages/main/Register";

import "primeicons/primeicons.css";
import "primereact/resources/themes/md-light-deeppurple/theme.css";

// import "../index.css"; //relates to premium content nav bar
const PremiumContent = React.lazy(() => import("../PremiumContent"));

export default function MainRouteSetup() {
  const location = useLocation();

  const FallbackLoader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      Loading...
    </div>
  );

  // Preload PremiumContent
  useEffect(() => {
    import("../PremiumContent");
  }, []);

  return (
    <PrimeReactProvider>
      <NextUIProvider>
        {!location.pathname.startsWith("/app") && <Navigation />}
        <div className="content">
          <Routes>
            <Route path="/" element={<MainHome />} />

            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/register"
              element={
                <PublicRoute>
                  <Register />
                </PublicRoute>
              }
            />
            <Route
              path="/app/*"
              element={
                <PrivateRoute>
                  <Suspense fallback={<FallbackLoader />}>
                    <PremiumContent />
                  </Suspense>
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
        {!location.pathname.startsWith("/app") && <Footer />}
      </NextUIProvider>
    </PrimeReactProvider>
  );
}
