import clsx from "clsx";
import "../../../../css/button.css";

export default function Button(props) {
  const { children, outline, className, theme, ...rest } = props;

  const classNames = clsx(
    // {
    //   btn: true,
    //   "btn-default": !outline,
    //   "btn-outline": outline,
    // },
    className,
    theme
  );

  return (
    <>
      <button className={classNames} {...rest}>
        {children}
      </button>
    </>
  );
}
