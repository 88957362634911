import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="footer">
      <ul>
        <li>Terms & Conditions</li>
        <li>Privacy Policy</li>
        <li>Security</li>
      </ul>
    </div>
  );
}
