import { getProfileImageURL } from "./getLocalStorageHelper";

export const setUserSession = (
  uid,
  businessID,
  businessName,
  emailAddress,
  user,
  token,
  profileImageID
) => {
  localStorage.setItem("uid", uid);
  localStorage.setItem("businessID", businessID);
  localStorage.setItem("businessName", businessName);
  localStorage.setItem("emailAddress", emailAddress);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
  localStorage.setItem("profileImageID", profileImageID);
};

export const setProfileImageURL = (profileImageURL) => {
  localStorage.setItem("profileImageURL", profileImageURL);
};

export const setUserPreferencesUserManagementColumns = (prefs) => {
  console.log("prefs: ", prefs);
  localStorage.setItem(
    "UserPreferences-UserManagementColumns",
    JSON.stringify(prefs)
  );
};

export const resetUserSession = () => {
  localStorage.removeItem("uid");
  localStorage.removeItem("businessID");
  localStorage.removeItem("businessName");
  localStorage.removeItem("emailAddress");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("profileImageID");
  localStorage.removeItem("profileImageURL");
  localStorage.removeItem("UserPreferences-UserManagementColumns");
};
