import React from "react";
import { BrowserRouter } from "react-router-dom";

import { createRoot } from "react-dom/client";
import App from "./App";
import "./css/index.css";

const root = document.querySelector("#ismsvision");

createRoot(root).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
