import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { axiosPost } from "./helpers/axiosHelper";
import { resetUserSession } from "./helpers/setLocalStorageHelper";
import { getUserEmail, getToken } from "./helpers/getLocalStorageHelper";
import MainRouteSetup from "./routes/MainRouteSetup";

const verifyTokenAPIURL = process.env.REACT_APP_VERIFY_API_URL;

export default function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const emailAddress = getUserEmail();
  const token = getToken();

  const navigate = useNavigate();

  useEffect(() => {
    const authenticate = async () => {
      if (!token) {
        setIsAuthenticating(false);
        navigate("/login");
        return;
      }

      const requestBody = {
        emailAddress: emailAddress,
        token: token,
      };

      try {
        const response = await axiosPost(verifyTokenAPIURL, requestBody);

        if (response.message === "success") {
          setIsAuthenticating(false);
        } else {
          resetUserSession();
          setIsAuthenticating(false);
          navigate("/login");
        }
      } catch (error) {
        resetUserSession();
        setIsAuthenticating(false);
        navigate("/login");
      }
    };
    authenticate();
  }, [token]);

  if (isAuthenticating && token) {
    return <div className="content"> Authenticating...</div>;
  }

  return <MainRouteSetup />;
}
